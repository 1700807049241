import React from "react"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
// import Table from "../components/table/table"
import Card from "../components/card/card"
import FAQs from "../components/faqs/faqs"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../../TC.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
// const footerDesc = "";
const contentMaxWidth = "980px";

/*const dataTable = {
    columns: ['','EVVA'],
    rows: [
        {
            desc: "Digital business account",
            evva: true
        },
        {
            desc: "High-yield checking",
            evva: true
        },
        {
            desc: "Bussines debit card",
            evva: true
        },
        {
            desc: "Time required to open",
            evva: "1 day"
        },
        {
            desc: "Onboarding experience",
            evva: "Fully digital"
        },
        {
            desc: "Personalized products",
            evva: true
        },
        {
            desc: "Customer service",
            evva: "Personalized"
        }
    ]
};*/

const dataFilters = [
    {
        id: "general",
        label: "General"
    },
    {
        id: "onboarding",
        label: "Onboarding"
    }
];

const dataFAQs = [
    {
        title: "Who is EVVA for?",
        description: "<p>We’re currently open to any Mexican residents who are a director of a limited company, and non-limited businesses that are structured as a partnership (Sociedades SAS, SAPI, SA de CV o SC) or sole trader.</p>",
        categories: ["general"]
    },
    {
        title: "What is EVVA?",
        description: "<p>EVVA is a digital platform which connects your to relevant financial services for your business and simplying your financial needs. All financial services offered on the platform are carried out by one or more financial institutions, which are regulated and supervised by the CNBV and CONDUSEF.</p>",
        categories: ["general"]
    },
    {
        title: "About business digital account",
        description: "<p>Our digital business account is a low risk investment account with immediate liquidity so you can withdraw your money at any time, the account is issued by Kuspit Casa de Bolsa and the funds are managed by Scotia Global Asset Management, a ScotiaBank company. For detailed information about this fund please read the following <a href='https://scotiabankfiles.azureedge.net/scotia-bank-mexico/spanish/pdf/personas/fondos-de-inversion/prospectos/Prospecto_SCOTIAG.pdf?t=1592919143996' target='_blank' rel='noopener noreferrer'>document</a></p>",
        categories: ["general"]
    },
    {
        title:"Why is the service called EVVA?",
        description: "<p>EVVA comes from our manifesto:<br /><strong>Entrepreneurship:</strong> our daily inspiration. We are focused on the entrepreneur’s financial needs so they can focus on what really matters: their business.</p><p><strong>Veracity:</strong> being transparent and direct with our customers. We are totally against hidden fees and complex pricings.</p><p><strong>Velocity:</strong> everything changes at a lightspeed pace. Being agile and adaptable is key to succeed. We craft agile financial experiences with the ultimate goal of making small businesses grow to the next level.</p><p><strong>Accessible:</strong> being inclusive. Our services must be open to all entrepreneurs that want to create an impact and contribute to its country’s economy.</p>",
        categories: ["general"]
    },
    {
        title: "What other services are available in EVVA?",
        description: "<p>With our products and allies we are developing a full suite of services for your business. Visit our <a href='/en/marketplace' target='_self' rel='noopener noreferrer'>marketplace page for more information.</a></p>",
        categories: ["general"]
    },
    {
        title: "What’s the best way to contact EVVA?",
        description: "<p>If you’re a customer Through the web app chat feature, where you can contact us during office hours.</p><p>You can also email us at <a href='mailto:susbcripciones@evvafinanzas.com' target='_blank' rel='noopener noreferrer'>susbcripciones@evvafinanzas.com</a> or ayuda@evvafinanzas.com to sort out issues or share ideas on how we can help you more.</p><p>If you’re curious drop us a line at <a href='mailto:info@evvafinanzas.com' target='_blank' rel='noopener noreferrer'>info@evvafinanzas.com</a> We’re always happy to answer questions.</p>",
        categories: ["general"]
    },
    {
        title: "How much does EVVA cost?",
        description: "<p>You pay a simple flat monthly fee, depending on how much you use EVVA and the stage of the company. Plans cost $0 for newly established companies and $175 MXN for companies already invoicing. For detailed information visit our pricing page.</p>",
        categories: ["general"]
    },
    {
        title: "What do I need for opening a business account business?",
        description: "<p>SMEs established as limited companies:</p><ul><li>CURP ID of legal representative</li><li>Front photo of the INE card of the legal representative, it must be in JPG, PNG format, you can take it with your cell phone or laptop,  ensure that it is completely clear</li><li>Photo of back of the voter card of the legal representative, it must be in JPG, PNG format, you can take it with your cell phone or laptop, ensure that it is completely clear</li><li>Articles of association in pdf format</li><li>Act of change of powers, this applies in case your company has suffered any change of legal representative</li><li>Proof of fiscal domicile in pdf, recent not more than 3 months, can be a water, electricity or phone bill</li><li>Tax certificate of your business, which you can generate on the SAT portal</li><li>FIEL (digital signature of your business) have your .KEY .CER files and password at hand</li></ul><p>If you are a sole proprietorship with business activit</p><ul><li>CURP ID</li><li>Front photo of the  INE card  of the legal representative, it must be in JPG, PNG format, you can take it with your cell phone or laptop, it only ensures that it is completely clear</li><li>Photo of back of the INE card of the legal representative, it must be in JPG, PNG format, you can take it with your cell phone or laptop, just ensure that it is completely clear</li><li>Proof of fiscal domicile in pdf, recent not more than 3 months, can be a water, electricity or phone bill</li></ul>",
        categories: ["onboarding"]
    },
    {
        title: "What information do I need from my business?",
        description: "<p>Business address, Tax ID RFC and phone number of your company</p>",
        categories: ["onboarding"]
    }
];

const Index = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const launchFormHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });

        const modal = {
            display: true,
            content: <LaunchForm lang={appState.lang} title="Thanks for your interest! By invitation only." subtitle="Request your invitation in order to get access to the platform." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpCreated"/>
        };

        dispatch(ACTIONS.showModal(modal))
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const goFinancingHandler = () => {
        navigate("/en/financing/")
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    const categoryFAQsHandler = (id) => {
        const { categoryFAQs } = appState;
        const categoryId = categoryFAQs.find(item => item === id);

        if (categoryId) {
            const index = categoryFAQs.indexOf(categoryId);

            if (index > -1 && categoryFAQs.length > 1) {
                categoryFAQs.splice(index, 1);
            }

        } else {
            //categoryFAQs.push(id)
            categoryFAQs[0] = id
        }

        dispatch(ACTIONS.setCategoryFAQs(categoryFAQs));
    };

    return (
        <Layout lang={appState.lang} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} title="With inflation your cash erodes over time." description="With EVVA beat inflation, make your cash work for you and generate daily returns with on-demand liquidity, 100% online."/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
            }
            <MainSection title="With inflation your cash erodes over time." description="<h3>Beat inflation, make your cash work for you and generate daily returns with on-demand liquidity.</h3>" position="left" image="hero-home-en-v3.png" actionLabel="Open an account" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUp">
                <AppBar pathname={appState.path} isHome={true}/>
            </MainSection>
            <PageSection>
                <div style={{marginTop: "-120px", zIndex: "1"}}>
                    <Card
                        subtitle="Get financing for your business"
                        description="<p>We have joined forces with Uniclick by Unifin, leader in the financing market, which has funded thousands SMEs in México.</p>"
                        descriptionColor="#363636"
                        image="search-icon.svg"
                        imageWidth="240px"
                        imageHeight="160px"
                        actionLabel="Learn more"
                        action={goFinancingHandler}
                        actionVariant="outlined"
                        bgColor="#fff"
                        horizontal
                        padding
                        shadow
                    />
                </div>
            </PageSection>
            <PageSection title="Everything you can expect from a standard business account but smarter..." maxWidth={contentMaxWidth}>
                <Card
                    title="100% Digital"
                    description="<p>Get on board online. No paperwork, bureaucracy and endless queues at the bank.</p>"
                    descriptionColor="#363636"
                    image="open-bank-account.png"
                    imageWidth="260px"
                    imageHeight="280px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
                <Card
                    title="For all sizes"
                    description="<p>Whether you're a Corporate, SME or Startup, We empower your finances.</p>"
                    descriptionColor="#363636"
                    image="wallet-reward-v2.png"
                    imageWidth="260px"
                    imageHeight="300px"
                    marginTopContent="60px"
                    reverseDirection
                    horizontal
                    padding
                />
                <Card
                    title="Grow"
                    description="<p>With EVVA your money is working for you and generating daily interest deposited directly on your account.</p>"
                    descriptionColor="#363636"
                    image="grow-en.png"
                    imageWidth="260px"
                    imageHeight="270px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
                <Card
                    title="Convenience"
                    description="<p>Get paid, transfer and pay using interbank transfer SPEI directly, during the time your money is not working.</p>"
                    descriptionColor="#363636"
                    image="convenience.png"
                    imageWidth="260px"
                    imageHeight="270px"
                    marginTopContent="60px"
                    reverseDirection
                    horizontal
                    padding
                />
            </PageSection>
            <PageSection maxColumns="3" title="Why is it better than a bank account?">
                {/*<Table data={dataTable}/>*/}
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="money-secured-icon.png"
                    description="<p><strong>Your money is yours</strong></p><p>Money deposited in a 'debit account' is on loan to the bank. In EVVA your money belongs really  to you. </p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="money-grow-icon.png"
                    description="<p><strong>Your money grows</strong></p><p>In traditional bank accounts, your money loses value over time. At EVVA it generates returns and grows.</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="money-difficult-find-icon.png"
                    description="<p><strong>Your money is safe</strong></p><p>Your business account is issued by Kuspit Casa de Bolsa, a financial institution regulated by BANXICO and CNBV.</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
            </PageSection>
            <PageSection maxWidth={contentMaxWidth} bgColor="#F3F4F6">
                <Card
                    title="You are protected"
                    description="<p>Your business account is issued by Kuspit Casa de Bolsa, a financial institution regulated by BANXICO and CNBV.</p><p>The funds are managed by Scotia Global Asset Management, a ScotiaBank company.</p>"
                    descriptionColor="#363636"
                    image="logos-protected-v4.png"
                    imageWidth="260px"
                    imageHeight="265px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
            </PageSection>
            <PageSection title="Frequently asked questions" maxWidth={contentMaxWidth}>
                <FAQs items={dataFAQs} filters={dataFilters} categoryFAQsHandler={categoryFAQsHandler} categoryFAQs={appState.categoryFAQs}/>
            </PageSection>
            <SecondarySection
                title="Together we grow"
                shortDescription="From Entrepreneurs to entrepreneurs, we are people like you who understand your needs. Our ultimate goal is to let you focus on what really matters ... Your business."
                position="center"
                image="evva-together.jpg"
                actionLabel="Open an account"
                action={launchFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUp"
            />
        </Layout>
    )
};

export default Index
