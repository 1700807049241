import React from "react"
import CollapsiblePanel from "../collapsiblePanel/collapsiblePanel"
import Chip from "../chip/chip"
import styles from "./faqs.module.scss"

const FAQs = ({items, filters, categoryFAQs, categoryFAQsHandler}) => {
    const itemsFiltered = [];

    items.forEach(item => {
        categoryFAQs.forEach(category => {
            if(item.categories.includes(category) && !itemsFiltered.includes(item)) {
                itemsFiltered.push(item)
            }
        })
    });

    return (
        <div className={styles["faqs"]}>
            <div className={styles["faqs-filters"]}>
                {filters.map((item) => (
                    <Chip key={item.id} text={item.label} onClick={() => categoryFAQsHandler(item.id)} isSelected={categoryFAQs.includes(item.id)}/>
                ))}
            </div>
            <div className={styles["faqs-items"]}>
                {itemsFiltered.map((item, index) =>
                    <CollapsiblePanel key={index} title={item.title} description={item.description} />
                )}
            </div>
        </div>
    )
};

export default FAQs;