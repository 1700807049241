import React, { useState } from "react"
import styles from "./collapsiblePanel.module.scss"

const CollapsiblePanel = ({title, description}) => {
    const setContent = content => ({__html: content});
    const [collapseDesc, setCollapseDesc] = useState(true);

    return (
        <div className={styles["collapsible-panel"]}>
            <h3>{title}</h3>
            <div className={collapseDesc ? styles["collapsible-panel_truncate"] : ""} dangerouslySetInnerHTML={setContent(description)}/>
            <div role="button" aria-label="collapseBtn" tabIndex={0} className={[styles["collapsible-panel_arrow"], styles[`collapsible-panel_arrow_${ collapseDesc ? "down" : "up"}`]].join(" ")} onClick={() => setCollapseDesc(!collapseDesc)} onKeyUp={(e) => e.keyCode === 13 ? setCollapseDesc(!collapseDesc) : e.preventDefault()}/>
        </div>
    )
};

export default CollapsiblePanel
