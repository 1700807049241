import React from "react"
import Icon from "../icon/icon"
import styles from "./chip.module.scss"

const Chip = ({text, isSelected, onClick}) => (
    <div role="button" tabIndex={0} className={[styles["chip"], isSelected ? styles["chip-selected"] : ""].join(" ")} onClick={onClick} onKeyUp={(e) => e.keyCode === 13 ? onClick : e.preventDefault()}>
        {text}
        {isSelected &&
        <div className={styles["chip-icon"]}>
            <Icon icon="success.svg" size="16px" />
        </div>
        }
    </div>
);

export default Chip